import { callAPI } from "utils/API";
import Activity from "./Activity";

export const addProductToBasket = async (ShopID, ProductID, VariationID, amount) => {
    const basketInfo = await callAPI('/basket', 'POST', {body:{ShopID, ProductID, VariationID, amount}})
    Activity({activityType: 'click',activityName: 'product',activityArgument: 'Basket',activityArgumentID: ProductID})
    return basketInfo
}

export const addPackageToBasket = async (ShopID, ProductPackageID, ProductID, VariationID, amount) => {
    const basketInfo = await callAPI('/basket', 'POST', {body:{ShopID, ProductPackageID, ProductID, VariationID, amount}})
    Activity({activityType: 'click',activityName: 'package',activityArgument: 'Basket',activityArgumentID: ProductPackageID})
    return basketInfo
}

export const removeProductFromBasket = async (BasketID) => {
    const basketInfo = await callAPI(`/basket/${BasketID}`, 'DELETE')
    return basketInfo
}

export const updateProductQuantityInBasket = async (BasketID, amount) => {
    const basketInfo = await callAPI(`/basket/${BasketID}`, 'PUT', {body:{amount}})
    Activity({activityType: 'click',activityName: 'basket',activityArgument: 'Update',activityArgumentID: BasketID})
    return basketInfo
}

export const addProductQuantityInBasket = async (BasketID, amount) => {
    const basketInfo = await callAPI(`/basket/${BasketID}/updateQuantity`, 'POST', {body:{amount}})
    Activity({activityType: 'click',activityName: 'basket',activityArgument: 'Update',activityArgumentID: BasketID})
    return basketInfo
}
