import { useCallback, useEffect, useRef, useState } from "react"

const HoverableImage = (props) => {
  const [showHoverImage, setShowHoverImage ] = useState(false);
  
  return (
    <div
      onMouseEnter={() => {
        if(props.hoverSrc){
          setShowHoverImage(true)
        }
      }}
      onMouseLeave={() => {
        if(props.hoverSrc){
          setShowHoverImage(false)
        }
      }}
    >
      <BackgroundImage onClick={props.onClick} size={props.size} className={props.className} src={props.src} style={{...props.style, opacity: showHoverImage ? "0" : "1"}} >{ props.children }</BackgroundImage>
      <BackgroundImage onClick={props.onClick} size={props.size} className={props.className} src={props.hoverSrc} style={{...props.style, opacity: showHoverImage ? "1" : "0"}}>{ props.children }</BackgroundImage>
    </div>
  )
  }

const BackgroundImage = (props) => {
  const {className, onClick, src, position, size, style, children, lazy} = props
  // const [showImage, setShowImage] = useState(false)
  const [source, setSource] = useState("");
  const imageRef = useRef(null);

  const callback = useCallback((entries) => {
    const [entry] = entries;
    if (entry.isIntersecting) {
      setSource(src);
    }
  }, [src]);

  useEffect(() => {
    // if(lazy === undefined) return setSource(src);
    
    const observer = new IntersectionObserver(callback, {rootMargin: "150px"});
    observer.observe(imageRef.current);
    return () => {
      observer.disconnect();
    };
  }, [callback, imageRef]);

  // useEffect(() => {
  //   if(className?.includes('lazy-visible')) return setShowImage(true)
  // },[className])

  // useEffect(() => console.log(showImage, className, src), [showImage])

  return (
    <div 
    ref={imageRef}
    onClick={onClick}
    className={className || ''}
    style={{
      backgroundRepeat: 'no-repeat',
      backgroundImage: source,
      backgroundPosition: position || 'center 0',
      backgroundSize: size || 'cover',
      ...style
    }}>
      {children}
    </div>
  )
}

export default HoverableImage;