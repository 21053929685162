import { AppContext } from "contexts/AppContext";
import { useContext, useState } from "react";
import { Container, Row } from "react-bootstrap";
import IconButtonMedium from "components/elements/IconButtonMedium";
import ButtonMedium1 from "components/elements/ButtonMedium1";
import Headline6 from "components/elements/Headline6";
import CaptionText from "components/elements/CaptionText";
import Image from "components/elements/Image";
import Heart2 from "components/icons/Heart2";
import LabelTag1 from "./LabelTag1";
import { createUseStyles } from "react-jss";
import ExplainerTextTiny from "./ExplainerTextTiny";
import { translate } from "utils/Translate";
import { useNavigate } from "react-router-dom";
import { callTagManagerEvent, setupBasketData } from "utils/setup";
import Headline5 from "./Headline5";
import { pointsConvertor } from "../../utils/general";
import LabelSticker from "./LabelSticker";
import Activity from "utils/Activity";
import HoverableImage from "./HoverableImage";

const jss = createUseStyles({
  ProductWrapper: {
    height: (styleGuide) => styleGuide.productNormalHeightMobile,
    width: (styleGuide) => styleGuide.productNormalWidthMobile,
  },
  ProductInfo: {
    flexDirection: "row",
    justifyContent: "space-between",
    height: 100,
    padding: "20px",
  },
  ProductNormalImage: {
    height: (styleGuide) =>
      !!styleGuide.productInfoAsOverlay ? `100%` : `calc(100% - 100px)`,
  },
  "@media screen and (min-width: 768px)": {
    ProductWrapper: {
      height: (styleGuide) => styleGuide.productNormalHeightTab,
      width: (styleGuide) => styleGuide.productNormalWidthTab,
    },
  },
  "@media screen and (min-width: 1280px)": {
    ProductWrapper: {
      height: (styleGuide) => styleGuide.productNormalHeight,
      width: (styleGuide) => styleGuide.productNormalWidth,
    },
  },
  "@media screen and (max-width: 768px)": {
    ProductInfo: {
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
      whiteSpace: "nowrap",
      overflow: "hidden",
      textOverflow: "ellipsis",
      textAlign: "center",
      gap: "15px",
      height: 130,
      padding: "10px",
    },
    ProductNormalImage: {
      height: (styleGuide) =>
        !!styleGuide.productInfoAsOverlay ? `100%` : `calc(100% - 130px)`,
    },
    TextAlignCenter: {
      textAlign: "center",
    },
  },
});

const ProductNormal = (props) => {
  const { styleGuide, userData, setBasket, brandData, defaultTheme, langCode } = useContext(AppContext);
  const navigate = useNavigate();
  const classes = jss(styleGuide);
  const showTag = !!props.showTag && !brandData?.features?.disablePoints;
  const hideLike = !!props.hideLike || brandData?.features?.disableWishlist ;
  const pointSuffix = translate("_POINTS_SHORT_", "pt")
  const {
    points,
    text1,
    text2,
    wishlistCallback,
    wishlistIconStyles,
    imageSrc,
    imageStyles,
    text1Styles,
    text2Styles,
    pointsStyles,
    tagOptions,
    tagStyles,
    liked,
    outOfStock,
    sticker,
    storeScrollPosition,
    hoverImageSrc
  } = props;  
  const showSticker = !brandData?.features?.disablePoints && !!sticker
  const taxProduct = userData.taxPercentage ? Math.ceil(points/(brandData.brandPointRatio || 1) * userData.taxPercentage/100) : null
  const productInfoStrip = (
    <ProductInfo
      {...{ text1, text1Styles, text2, text2Styles, points, pointsStyles, taxProduct }}
    />
  );
  const isLikedProduct = typeof liked === "boolean" ? liked : false;
  const likeUnlike = async (e) => {
    if (wishlistCallback) await wishlistCallback(e);
    await setupBasketData({ userData, setBasket });
  };

  const isUserAdmin = () => {
    if (userData?.userPrivilege == "MANAGE") {
        return true;
    } else {
        return false;
    }
}
  const goToProduct = () => {
    if(storeScrollPosition === true) {
      sessionStorage.setItem('scrollPosition', `${window.pageYOffset || ''}`);
      sessionStorage.setItem('productClickedOnShop', `ProductNormal-${props.productID}`);    
    }
    callTagManagerEvent({
      dataLayer: {
        event: 'view_item',
        ecommerce: {
          currency: 'DKK',
          value: points,
          items: [{
              item_name: text2,
              item_id: props.ProductID,
              item_brand: text1,
              item_category: '',
              item_variant: '',
              quantity: 1,
              price: points
          }]
        }
      },
    });
    navigate(`/product/${props.productID}`)
  }
  const goToPackage = () => {
    if(storeScrollPosition === true) {
      sessionStorage.setItem('scrollPosition', `${window.pageYOffset || ''}`);
      sessionStorage.setItem('productClickedOnShop', `ProductNormal-${props.packageID}`);    
    }
    navigate(`/package/${props.packageID}`)
  }
  return (
    <div
      id={`ProductNormal-${props.productID}`}
      className={`${classes.ProductWrapper} ${
        props.className ? props.className : ""
      }`}
      style={{
        display: "flex",
        position: "relative",
        flexDirection: "column",
        cursor: "pointer",
        ...props.style,
      }}
    >
      <div
        onClick={() => {
          if (!outOfStock && props.productID) {
            Activity({ activityType: 'click', activityName:props.activityName, activityArgument: 'Product', activityArgumentID: props.productID});
            goToProduct()
          } else if(props.packageID) {
            Activity({ activityType: 'click', activityName:props.activityName, activityArgument: 'Package', activityArgumentID: props.packageID});
            goToPackage()
          }
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: showTag ? "space-between" : "flex-end",
          }}
        >
          {showTag ? (
            <LabelTag1
              {...tagOptions}
              style={{
                alignSelf: "flex-start",
                marginTop: "20px",
                marginLeft: "20px",
                zIndex: 1,
                ...tagStyles,
              }}
            />
          ) : null}
          {!hideLike ? (
            <IconButtonMedium
              inverse={isLikedProduct ? true : false}
              style={{
                alignSelf: "flex-end",
                marginTop: "20px",
                marginRight: "20px",
                zIndex: 1,
                ...wishlistIconStyles,
              }}
              onClick={(e) => {
                if (!outOfStock) {
                  e.stopPropagation();
                  likeUnlike(e);
                }
              }}
              icon={
                <Heart2
                  fill="white"
                  style={{ height: 10, width: 10 }}
                  stroke={styleGuide.color7}
                  strokeWidth="1.5px"
                />
              }
            />
          ) : null}
        </div>
        { showSticker ? (
          <LabelSticker type={sticker} price={points}
          className="labelSticker"/>
        ) : null }
        <HoverableImage
          src={imageSrc}
          hoverSrc={hoverImageSrc}
          className={`${classes.ProductNormalImage}`}
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            zIndex: 0,
            width: "100%",
            backgroundColor: styleGuide.productInfoBackground,
            display: "flex",
            alignItems: "flex-end",
            justifyContent: "flex-end",
            transition: "all .3s ease-in-out",
            ...imageStyles,
          }}
        >
          {((brandData?.features?.onlyAdminOrderEnabled) && !isUserAdmin()) ? <div></div> : 
          brandData?.features?.points === false || brandData?.features?.disablePoints ? null : points && 
          <div className={classes.buttonMobile} style={{display: "flex",flexDirection: "row"}}>
            <ButtonMedium1
              showIcon={false}
              style={{
                ...pointsStyles,
                minWidth: "101px",
                justifyContent: "center",
                marginRight: 15,
                marginBottom: defaultTheme === "ThemeTWO" ? 115 : 15,
              }}
            >
              {!!brandData?.features?.disablePrice ? brandData?.features?.productButtonText[langCode] : pointsConvertor(brandData,points, pointSuffix)}
            </ButtonMedium1>
          </div>}
          {!!styleGuide.productInfoAsOverlay ? productInfoStrip : null}
        </HoverableImage>
        
        {outOfStock && (
          <div className="outOfStock">
            <Headline5 style={{ color: "white" }}>
              {translate("_PRODUCT_SOLD_OUT_TEXT_", "Produktet er udsolgt")}
            </Headline5>
            <ButtonMedium1
              onClick={(e) => {
                e.stopPropagation();
                likeUnlike(e);
              }}
              showIcon={false}
              style={{
                maxWidth: 175,
                color: "black",
                backgroundColor: "white",
              }}
            >
              {translate("_REMOVE_FROM_WISHLIST_", "Fjern fra ønskeliste")}
            </ButtonMedium1>
          </div>
        )}
      </div>
      {!styleGuide.productInfoAsOverlay ? productInfoStrip : null}
    </div>
  );
};

const ProductInfo = (props) => {
  const { styleGuide, userData,brandData } = useContext(AppContext);
  const classes = jss(styleGuide);
  const { points, text1, text2, text1Styles, text2Styles, pointsStyles, taxProduct } =
    props;
  const navigate = useNavigate();
  const productPrice = points && points.match(/\d/g).join("");
  const walletAmountPoints =
    userData && !isNaN(userData.walletAmountPoints)
      ? Number(userData.walletAmountPoints)
      : 0;
  const deficitPoints = !isNaN(productPrice)
    ? productPrice - walletAmountPoints
    : 0;
  const isDeficit = !!brandData?.features?.disablePoints || !!brandData?.features?.skipWalletForOrders || brandData?.features?.enableOnePointUsers ? false : deficitPoints > 0 ? true : false
console.log(points)
  return (
    <div
      className={classes.ProductInfo}
      style={{
        position: "absolute",
        bottom: 0,
        zIndex: 1,
        width: "100%",
        maxWidth: "100%",
        background: styleGuide.productInfoBackground,
        display: "flex",
      }}
    >
      {!!taxProduct && !!points && (
        <div
          style={{
            position: "absolute",
            bottom: 84,
            left: 0,
            right: 0,
            backgroundColor: styleGuide.productInfoBackground,
            textAlign: "end",
            borderBottom: `1px solid ${styleGuide.color4}`,
            height: 18
          }}
        >
          <ExplainerTextTiny
            style={{ verticalAlign: "text-top", marginRight: 16, paddingTop: 0, paddingBottom: 0, lineHeight: '12px' }}
            fallbackText={`${translate('_PRODUCT_TAXATION_TEXT_', 'Beskatning kr.')} ${pointsConvertor(brandData,taxProduct,'')}`}
          />
        </div>
      )}
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          whiteSpace: "nowrap",
          overflow: "hidden",
          textOverflow: "ellipsis",
        }}
      >
        <Headline6
          title={text1}
          className={classes.TextAlignCenter}
          style={{
            color: styleGuide.productInfoText1Color,
            ...text1Styles,
            whiteSpace: "break-spaces",
            overflow: "hidden",
            textOverflow: "ellipsis",
          }}
        >
          {text1 || "\u00A0"}
        </Headline6>
        <CaptionText
          title={text2}
          className={classes.TextAlignCenter}
          style={{
            color: styleGuide.productInfoText2Color,
            ...text2Styles,
            whiteSpace: "break-spaces",
            overflow: "hidden",
            textOverflow: "ellipsis",
          }}
        >
          {text2 || "\u00A0"}
        </CaptionText>
      </div>
      {!!isDeficit && points && (
        <div
          style={{
            position: "absolute",
            bottom: 0,
            left: 0,
            right: 0,
            backgroundColor: styleGuide.color3,
            textAlign: "center",
          }}
        >
          <ExplainerTextTiny
            style={{ verticalAlign: "middle" }}
            fallbackText={`${translate(
              "_USER_POINT_DEFICIENT_FOR_PRODUCT_TEXT_",
              "Du mangler"
            )} ${pointsConvertor(brandData,deficitPoints, translate("_POINTS_", "point"))}`}
          />
        </div>
      )}
    </div>
  );
};

export default ProductNormal;
