import { Routes, Route, useParams } from "react-router-dom";
import Page from "components/layouts/Page";
import NoMatch from "components/elements/NoMatch";
import CheckAuth from "utils/checkAuth";
import Home from "modules/home/Home";
import Login from "modules/session/Login";
import PostList from "modules/post/PostList";
import Example from "modules/example/Example";
import ExampleBlocks from "modules/example/ExampleBlocks";
import Logout from "modules/session/Logout";
import PostDetails from "modules/post/PostDetails";
import Shop from "modules/shop/Shop";
import Product from "modules/product/Product";
import Basket from "modules/basket/Basket";
import Profile from "modules/profile/Profile";
import Help from "modules/help/Help";
import Search from "modules/search/Search";
import TermsOfTrade from "modules/termsOfTrade/TermsOfTrade";
import CookiePage from "modules/cookie/CookiePage";
import PersonalShopper from "modules/personalShopper/PersonalShopper";
import Team from "modules/team/Team";
import HOF from "modules/hof/hof";
import SSO from "modules/session/SSO";
import CompetitionDetails from "modules/competition/competitionDetails";
import ActivationDetails from "modules/activation/activationDetails";
import Report from "modules/Report/Report";
import Register from "modules/register/Register"
import UnderMaintenance from "modules/session/underMaintenance";
import ChristmasCalendarDetail from "modules/christmasCalendar/ChristmasCalendarDetail";
import Package from "modules/package/Package";
import { useContext, useEffect } from "react";
import { AppContext } from "contexts/AppContext";
import ShadowUser from "modules/session/shadow";
import { callTagManagerEvent } from "utils/setup";

const UpgraderClubRouter = () => {
    const {brandData} = useContext(AppContext)
    const {disableShop, disableNews, disableHome, disableTaxCampaignPosts, aliasMap} = brandData?.features
    const homeComponent = () => {
        if(!disableHome) return <Home />
        if(!disableShop) return <Shop />
        if(!disableNews) return <PostList />
        return <Profile />
    }

    const homeText = () => {
        if(!disableHome) return "home"
        if(!disableShop) return "shop"
        if(!disableNews) return "posts"
        return "profile"
    }

    const innerRoutes = [
        {
            ignore: false,
            path: 'logout',
            element: <Logout />,
            pageName: "logout",
            paramName: "",
        },
        {
            ignore: false,
            path: 'activationDetails',
            element: <ActivationDetails />,
            pageName: "activationDetails",
            paramName: "",
        },
        {
            ignore: false,
            path: 'competition/:CompetitionID',
            element: <CompetitionDetails />,
            pageName: "competition",
            paramName: "CompetitionID",
        },
        {
            ignore: false,
            path: 'christmas-calendar/:CompetitionID',
            element: <ChristmasCalendarDetail />,
            pageName: "christmas-calendar",
            paramName: "CompetitionID",
        },
        {
            ignore: !!disableNews,
            path: "posts",
            element: <PostList />,
            pageName: "posts",
            paramName: ""
        },
        {
            ignore: !!disableNews,
            path: "competitions",
            element: <PostList postType=""/>,
            pageName: "competitions",
            paramName: ""
        },
        {
            ignore: !!disableNews,
            path: "posts/:BlogID",
            element: <PostList />,
            pageName: "posts",
            paramName: "BlogID"
        },
        {
            ignore: !!disableNews,
            path: "post/:PostID",
            element: <PostDetails />,
            pageName: "post",
            paramName: "PostID"
        },
        {
            ignore: !!disableTaxCampaignPosts,
            path: "postcampaigns",
            element: <PostList postType="taxCampaign"/>,
            pageName: "posts",
            paramName: ""
        },
        {
            ignore: !!disableTaxCampaignPosts,
            path: "postcampaigns/:BlogID",
            element: <PostList postType="taxCampaign"/>,
            pageName: "posts",
            paramName: "BlogID"
        },
        {
            ignore: !!disableTaxCampaignPosts,
            path: "postcampaign/:PostID",
            element: <PostDetails postType="taxCampaign"/>,
            pageName: "post",
            paramName: "PostID"
        },
        {
            ignore: !!disableShop,
            path: "shop/:ShopID",
            element: <Shop />,
            pageName: "shop",
            paramName: "ShopID"
        },
        {
            ignore: !!disableShop,
            path: "product/:ProductID",
            element: <Product />,
            pageName: "product",
            paramName: "ProductID"
        },
        {
            ignore: !!disableShop,
            path: "package/:PackageID",
            element: <Package />,
            pageName: "package",
            paramName: "PackageID"
        },
        {
            ignore: !!disableShop,
            path: "wishlist",
            element: <Shop />,
            pageName: "wishlist",
            paramName: ""
        },
        {
            ignore: !!disableShop,
            path: "basket",
            element: <Basket />,
            pageName: "basket",
            paramName: ""
        },
        {
            ignore: false,
            path: "profile",
            element: <Profile />,
            pageName: "profile",
            paramName: ""
        },
        {
            ignore: false,
            path: "profile/:Subpage",
            element: <Profile />,
            pageName: "profileSettings",
            paramName: ""
        },
        {
            ignore: false,
            path: "help",
            element: <Help />,
            pageName: "help",
            paramName: ""
        },
        {
            ignore: false,
            path: "search",
            element: <Search />,
            pageName: "search",
            paramName: ""
        },
        {
            ignore: false,
            path: "terms",
            element: <TermsOfTrade />,
            pageName: "terms",
            paramName: ""
        },
        {
            ignore: false,
            path: "team",
            element: <Team />,
            pageName: "team",
            paramName: ""
        },
        {
            ignore: false,
            path: "personalShopper",
            element: <PersonalShopper />,
            pageName: "personalShopper",
            paramName: ""
        },
        {
            ignore: false,
            path: "halloffame/:HalloffameID",
            element: <HOF />,
            pageName: "halloffame",
            paramName: "HalloffameID"
        },
        {
            ignore: false,
            path: "indberetning",
            element: <Report />,
            pageName: "indberetning",
            paramName: ""
        },
        // {
        //     ignore: false,
        //     path: ":alias",
        //     element: <Alias />,
        //     pageName: "alias",
        //     paramName: "alias"
        // },
    ]

    const generateInnerRoutes = () => innerRoutes
        .filter(r => !r.ignore)
        .map((r,ix) => <Route key={ix} exact 
            path={r.path}
            element={
                <PageWithGTMApplied 
                    component={r.element} 
                    pageName={r.pageName} 
                    paramName={r.paramName} 
                />
            }
        />
    )

    const generateDynamicRouteAliases = () => {
        if(!aliasMap?.length) return null
        return aliasMap?.map((x,i) => {
            let routeDefinition = innerRoutes?.filter(ir => {
                return "/" + ir.path === x.path
            })
            console.log(routeDefinition)

            if(!routeDefinition?.length) 
                return null 
            routeDefinition = routeDefinition[0] || {}
            const y = x.alias.map(xa => ({...routeDefinition, path: xa}))
            console.log(y)
            return y
        })
        .filter(a => a !== null)
        .flat()
        .map((r,ix) => <Route key={`alias-${ix}`} exact 
            path={r.path}
            element={
                <PageWithGTMApplied 
                    component={r.element} 
                    pageName={r.pageName} 
                    paramName={r.paramName} 
                />
            }
        />)
    }

    return (
        <Routes>
            <Route path="maintenance" element={<PageWithGTMApplied component={<UnderMaintenance/>} pageName="maintenance" paramName="" />}/>
            <Route path="register" element={<PageWithGTMApplied component={<Register/>} pageName="register" paramName="" />}/>
            <Route path="login" element={<PageWithGTMApplied component={<Login/>} pageName="login" paramName="" />} />
            <Route path="sso" element={<PageWithGTMApplied component={<SSO/>} pageName="sso" paramName="" />} />
            <Route path="shadow" element={<PageWithGTMApplied component={<ShadowUser/>} pageName="shadow" paramName="" />} />
            <Route exact path="cookie-policy" element={<PageWithGTMApplied component={<CookiePage/>} pageName="cookie-policy" paramName="" />}/>
            <Route element={<CheckAuth><Page /></CheckAuth>}>
                <Route exact path="/" element={<PageWithGTMApplied component={homeComponent()} pageName={homeText()} paramName="" />} />
                { generateInnerRoutes() }
                { generateDynamicRouteAliases() }
            </Route>

            {/* example pages for demo purpose */}
            <Route exact path="example" element={<PageWithGTMApplied component={<Example/>} pageName="" paramName="" />} />
            <Route exact path="example-blocks" element={<ExampleBlocks />} />
            <Route exact path="example-product" element={<Product />} />

            <Route path="*" element={<NoMatch />} />
        </Routes>
    )
}

export default UpgraderClubRouter

const PageWithGTMApplied = (props) => {
    const {pageName, paramName, component } = props
    const params = useParams()

    useEffect(()=>{
        if(!pageName) return

        callTagManagerEvent({
            dataLayer: {
                event: 'page_view',
                page: !!paramName ? `${pageName}/${params[paramName]}` : pageName
            },
        })
        // console.log(!!paramName ? `${pageName}/${params[paramName]}` : pageName)    
    },[pageName, paramName])

    return <>{component}</>
}